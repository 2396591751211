import React from 'react';
import Menu from './components/Menu.js';
import WrapperLayout from './components/WrapperLayout.js';
import FooterLayout from './components/FooterLayout.js';
import Login from './components/Login.js';

function App() {
    return (
      <div id="app">
        <Menu />
        {
          localStorage.getItem("session_token") === null ? <Login /> : <WrapperLayout />
        }
        <FooterLayout />
      </div>
    );
}

export default App;
