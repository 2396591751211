import React from 'react';
import { Button, Form, Col, Spinner } from 'react-bootstrap';
import { ExecuteAsPost } from '../utils';
import InputMask from 'react-input-mask';


export default class Mailing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSent: false,
            range: { from: "", to: ""}
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    async handleSubmit(e) {
        e.preventDefault();

        let range = this.state.range;

        range.from = this.refs.From.value;
        range.to = this.refs.To.value;

        this.setState({isSent: true });
        try {
            await ExecuteAsPost("mailing/execute", range); 
        }
        catch {

        }
        this.setState({isSent: false });
    }
      
    render() {
        return (
            this.state.isSent
            ?
            <div className="container" style={{height: "800px", overflowY: "auto"}}>
                <h2>Рассылка</h2>
                <Spinner animation="border" variant="info">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
            :
            <div className="container" style={{height: "800px", overflowY: "auto"}}>
                <h2>Рассылка</h2>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Row style={{padding: "10px", width: "70%", margin: "0px auto"}}>
                        <Col>
                            <InputMask mask="9999-99-99" placeholder="ГГГГ-ММ-ДД" style={{margin: "2px", textAlign: "center"}} ref="From"/>
                        </Col>
                        <Col>
                            <InputMask mask="9999-99-99" placeholder="ГГГГ-ММ-ДД" style={{margin: "2px", textAlign: "center"}} ref="To"/>
                        </Col>
                        <Button variant="primary" type="submit" style={{margin: "5px auto", padding: "5px"}}>
                            Выполнить рассылку
                        </Button>
                    </Form.Row>
                </Form>
            </div>
        );
    }
}