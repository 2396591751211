import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './Home';
import NotFound from './NotFound';
import Orders from './Orders';
import Add from './Add';
import Mailing from './Mailing';

export default class WrapperLayout extends React.Component {
    render() {
        return (
            <div id="wrapper">
                <Container>
                    <Row className="justify-content-md-center">

                        <Col sm={8}>
                            <div id="content">
                                <Router>
                                    <Switch>
                                        <Route exact path="/" component={Home} />
                                        <Route exact path="/home" component={Home} />
                                        <Route path="/add" component={Add} />
                                        <Route path="/orders" component={Orders} />
                                        <Route path="/mailing" component={Mailing} />
                                        <Route component={NotFound} />
                                    </Switch>
                                </Router>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}