import React from 'react';
import { Button, Form, InputGroup, Alert } from 'react-bootstrap';
import Octicon from 'react-component-octicons';
import { ExecuteAsPost } from '../utils';


export default class Login extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            formSent: false,
            authorized: false,
            errors: null
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }
    async handleSubmit(e) {
        e.preventDefault();

        let user = {
            Login: this.refs.login.value,
            Password: this.refs.password.value,
        };
        const response = await ExecuteAsPost("user/login", user);

        if (response.success) {
            this.setState({formSent: true, authorized: true })
            localStorage.setItem('session_token', "Bearer " + response.data);
        }
        else {
            this.setState({formSent: true, authorized: false, errors: response.errors })
        }
        
    }
    render() {
        if (this.state.authorized){
            window.location.reload();
        }
        return (
            <div className="container">
                <h2>Авторизация</h2>
				<br/>
				<br/>
                <Form style={{ width: "250px", margin: "0 auto" }} onSubmit={this.handleSubmit}>
                    <Form.Group controlId="formLogin">
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-sm"><Octicon name="person" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="text" placeholder="Enter login" name="login" ref="login"/>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group controlId="formPassword">
                        <InputGroup size="sm" className="mb-3">
                            <InputGroup.Prepend>
                                <InputGroup.Text id="inputGroup-sizing-sm"><Octicon name="lock" /></InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="password" placeholder="Password" name="password" ref="password"/>
                        </InputGroup>
                    </Form.Group>

                    <Button variant="primary" type="submit">
                        Войти
                    </Button>
                    {
                    this.state.formSent ?
                        this.state.authorized ?
                        <Alert variant="success">Авторизация прошла успешно.</Alert>
                        :
                        <Alert variant="danger">
                            {
                                this.state.errors.map((error, index) => 
                                <p style={{margin: "0px"}} key={index}>{error}</p>
                                )
                            }
                        </Alert>
                        :
                        <div></div>
                    }
                </Form>
            </div>);
    }
}