import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
 
export default class Menu extends React.Component {
    render()
    {
        return (
            <div id="header">
                <Navbar bg="dark" variant="dark">
                    <Navbar.Brand style={{ padding: '0px'}} href="/home">
                        <img src="/static/images/logo.png" style={{ height: '65px'}}
                            className="d-inline-block align-top"
                            alt="Amsterdamster logo"
                        />
                    </Navbar.Brand>
                    <Nav activeKey={window.location.pathname} className="mr-auto">
                        <Nav.Link href="/add">Добавить</Nav.Link>
                        <Nav.Link href="/orders">Просмотреть</Nav.Link>
                        <Nav.Link href="/mailing">Рассылка</Nav.Link>
                    </Nav>
                </Navbar>
            </div>
        );    
    }
}