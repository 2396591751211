import React from 'react';
import { ExecuteAsPost, ExecuteAsGet, ExecuteAsDelete } from '../utils';
import { Spinner, Table, Form, Col, Button, Alert } from 'react-bootstrap';
import Octicon from 'react-component-octicons';
import InputMask from 'react-input-mask';

export default class Orders extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            orders: []
        }
        this.handleClick = this.handleClick.bind(this);
    }

    componentDidMount() {
        this.PreFetchOrders();
    }

    handleClick() {
        this.FetchOrders();
    }

    async PreFetchOrders(){
        let response = await ExecuteAsGet("order/all");
        this.setState({orders: response.data, loading: false});
    }
    handleDelete = (id, index) => {
        let array = [...this.state.orders];

        if (index !== -1) {
            ExecuteAsDelete("order/delete", id);
            array.splice(index, 1);
            this.setState({orders: array});
        }
    }

    async FetchOrders(){
        let dataRange = {
            From: this.refs.From.value,
            To: this.refs.To.value
        };
        let response = await ExecuteAsPost("order/report", dataRange);
        
        if (response != null)
        {
            this.setState({orders: response.data, loading: false});
        }
    }

    renderOrdersTable(orders)
    {
        if (orders != null) {
            if (orders.length > 0) {
                return (
                    <Table striped bordered hover variant="dark">
                        <thead>
                            <tr>
                                <th>Клиент</th>
                                <th>Телефон</th>
                                <th>Дата</th>
                            </tr>
                        </thead>
                        <tbody>
                            {orders.map((orders, index) =>
                                <tr key={orders.id}>
                                    <td>{orders.clientName}</td>
                                    <td>{orders.clientPhone}</td>
                                    <td>{orders.creationDate ?? "-"}</td>
                                    <td><Button variant="danger" type="submit" style={{padding: "0px 5px" }} onClick={() => this.handleDelete(orders.id, index)}>Удалить</Button></td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                );
            }
            else {
                return (
                    <Alert variant="success" style={{padding: "5px", margin: "0px auto", width: "50%", textAlign: "center"}}>Данных за данный период нет.</Alert>
                );
            }
        }
        else {
            return (
                <Alert variant="danger" style={{padding: "5px", margin: "0px auto", width: "50%", textAlign: "center"}}>Проверье введенные даты!</Alert>
            );
        }
    }

    render() {
        let contents = this.state.loading ?
        
        <div className="container" style={{width: "10%", margin: "0px auto"}}>
            <Spinner animation="border" variant="info">
                <span className="sr-only">Loading...</span>
            </Spinner>
        </div>
        :
        this.renderOrdersTable(this.state.orders)

        return (
            <div className="container" style={{height: "800px", overflowY: "auto"}}>
                <h2>Брони</h2>
                    <Form.Row style={{padding: "10px", width: "70%", margin: "0px auto"}}>
                        <Col>
                            <InputMask mask="9999-99-99" placeholder="ГГГГ-ММ-ДД" style={{margin: "2px", textAlign: "center"}} ref="From"/>
                        </Col>
                        <Col>
                            <InputMask mask="9999-99-99" placeholder="ГГГГ-ММ-ДД" style={{margin: "2px", textAlign: "center"}} ref="To"/>
                        </Col>
                        <Col>
                            <Button variant="primary" type="submit" style={{margin: "2px", height: "30px"}} onClick={this.handleClick}>
                                <Octicon name="search" style={{width: "20px", height: "20px"}}/>
                            </Button>
                        </Col>
                    </Form.Row>
                {contents}
            </div>
        );
    }
}