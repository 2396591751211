import React from 'react';
import { Button, Form, Col, Alert } from 'react-bootstrap';
import { ExecuteAsPost } from '../utils';
import InputMask from 'react-input-mask';


export default class Add extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isSent: false,
            response: [],
            total: 1,
            orders: [ { clientName: "", clientPhone: ""}]
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    add = () => {
        this.setState({ total: this.state.total+1, orders: [...this.state.orders, {client: "", phone: ""}, ] })
    }

    remove = () => {
        if (this.state.total > 1) {
            let orders = [...this.state.orders];
            orders.splice(this.state.total-1, 1);
    
            this.setState({ total: this.state.total-1, orders : orders})
        }
    }
    
    onChange = (event, index) => {
        let orders = this.state.orders;

        if (event.target.name === "clientInput") {
            orders[index-1].clientName = event.target.value;
        }
        else if (event.target.name === "phoneInput") {
            orders[index-1].clientPhone = event.target.value;
        }
        this.setState({orders: orders });
    }

    async handleSubmit(e) {
        e.preventDefault();

        const response = await ExecuteAsPost("order/add", this.state.orders); 

        if (response != null){
            this.setState({isSent: true, response: response });

            if (this.state.response.success) {
                this.setState({total: 1, orders: [ { clientName: "", clientPhone: ""}]});
            }
        }
    }
      
    render() {
        const inputs = [];

        for (let i = 1; i <= this.state.total; i++) {
            inputs.push(<div className={`inputContainer-${i}`} key={`inputContainer-${i}`}>
                <Form.Row>
                    <Col>
                        <Form.Control name="clientInput" type="text" placeholder="Имя клиента" onChange={(e) => this.onChange(e, i)}/>
                    </Col>
                    <Col>
                        <InputMask name="phoneInput" mask="+3\80999999999" maskChar="" placeholder="+380933210000" style={{width: "100%", dispaly: "block", height: "calc(1.5em + .75rem + 2px)", padding: ".375rem .75rem", border: "1px solid #ced4da", borderRadius: ".25rem", transition: "border-color .15s ease-in-out, box-shadow .15s ease-in-out", color: "#495057"}} onChange={(e) => this.onChange(e, i)}/>
                    </Col>
                </Form.Row>
                <hr/>
            </div>
          )
        }
        return (
            <div className="container" style={{height: "800px", overflowY: "auto"}}>
                <h2>Добавить</h2>
                    <Form onSubmit={this.handleSubmit}>
                        {inputs}
                        <div className="navBar" style={{width: "100%", height: "40px"}}>
                        <Button style={{padding: "0px", margin: "5px", height: "30px", width: "30px", borderRadius: "50%", float: "right"}} variant="danger" onClick={this.remove}>-</Button>
                            <Button style={{padding: "0px", margin: "5px", height: "30px", width: "30px", borderRadius: "50%", float: "right"}} variant="success" onClick={this.add}>+</Button>
                        </div>
                        <Button style={{margin: "10px"}}variant="secondary" type="submit">Добавить</Button>
                    </Form>
                    <div className="container">
                        {
                        this.state.isSent ?
                            this.state.response.success ?
                            <Alert variant="success">
                                {this.state.response.data}
                            </Alert>
                            :
                            <Alert variant="danger" style={{padding: "5px", margin: "0px auto", width: "50%", textAlign: "center"}}>
                                {
                                    this.state.response.errors.map((error, index) => 
                                    <p style={{margin: "0px"}} key={index}>{error}</p>
                                    )
                                }
                            </Alert>
                            :
                            <div></div>
                        }
                    </div>
            </div>);
    }
}