import React from "react";

export default class FooterLayout extends React.Component {
    render() {
        return (
            <div id="footer" className="fixed-bottom">
                <footer id="sticky-footer" className="py-2 bg-dark text-white-50">
                    <div className="container text-center">
                        <small>
                            Copyrigh &copy; {new Date().getFullYear()}, <a href="https://amsterdamster.com.ua"> Amsterdamster </a>
                        </small>
                    </div>
                </footer>
            </div>
        );
    }
}