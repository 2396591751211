import React from 'react';
 
export default class Home extends React.Component{
    render()
    {
        return (
            <div className="container">
                <h4>
                   Главная
                </h4>
                Здесь можно написать какой-то текст
            </div>
        );
    }
}