const apiEndpoint = "http://116.203.200.167:5000/api/";

//const apiEndpoint = "http://localhost:5000/api/";
export async function ExecuteAsPost(path, data) {
  const url = `${apiEndpoint}${path}`;

  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("session_token")
    },
    body: JSON.stringify(data)
  });

  if (response.status === 401)
  {
    localStorage.removeItem('session_token');
  }
  return await response.json();
}

export async function ExecuteAsDelete(path, data) {
  const url = `${apiEndpoint}${path}`;

  const response = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("session_token")
    },
    body: JSON.stringify(data)
  });

  if (response.status === 401)
  {
    localStorage.removeItem('session_token');
  }

    return await response.json();
}

export async function ExecuteAsGet(path) {
  const url = `${apiEndpoint}${path}`;

  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': localStorage.getItem("session_token")
    },
  });

  if (response.status === 401)
  {
    localStorage.removeItem('session_token');
  }

    return await response.json();
}